import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { apirc } from '~/configs/apirc';
import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import dayAPI from '~/utils/dayAPI';
import { proxy, useSnapshot } from 'valtio';
import { MonthContractKbar } from './component/stockbarBoxView/MonthContractKbar';
import { dailyAmplitudeStatsCalculator } from './component/dailyAmplitudeStatsCalculator';
import { PriceLevelItem } from './component/PriceLevelItem';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { freemanStore } from './freemanStore';
import { store } from '../heineken_template/_private/store';
import { PriceDiff } from './component/PriceDiff';
import { IntervalSwitch } from './component/IntervalSwitch';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { staticStore } from '../heineken_template/_private/staticStore';
const ROOT_URL = apirc.tvAPIs;
/** 各週期的空值資料typing */
const emptyData = {
    t: [0],
    o: [0],
    h: [0],
    l: [0],
    c: [0],
    v: [0],
    s: 'ok',
};
//快照 當資料從空值轉為有數值時 給與新值
const contractStore = proxy({
    dayData: emptyData,
    // weekData: emptyData as TVAPISymbol,
    // monthData: emptyData as TVAPISymbol,
});
//等待抽出
/** 獲取合約週期報價 */
export const useGetDataInfo = (options) => {
    const { symbol, resolution, from, refresh } = options;
    const url = urlcat(`${ROOT_URL}/history`, { symbol, resolution, from });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        refreshInterval: refresh * 60 * 1000,
    });
    return { data: res.data };
};
const ContractData = memo(function SidePane(props_) {
    const state = useSnapshot(freemanStore);
    const tabState = state.ampTabs;
    const symbol = state.tradeSymbol;
    const tradeDate = useSnapshot(staticStore.tradedDate.days);
    //需要改進: 當報價大於合約最低最高 則再次拿取api
    useEffect(() => {
        signalrStore2.addQuote(['TXAM-1', 'TX-1', 'TX-2', 'TSEA']);
        return () => {
            signalrStore2.removeQuote(['TXAM-1', 'TX-1', 'TX-2', 'TSEA']);
        };
    }, [JSON.stringify(['TXAM-1', 'TX-1', 'TX-2', 'TSEA'])]);
    const value = useSnapshot(signalrStore2.values.quote)[symbol];
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    //------------------------------------------------
    /** 使用前21個月交易日的日期 */
    const fromMounth = dayAPI().subtract(21, 'month').set('second', 0).unix();
    /** 使用前21個周交易日的日期 */
    const fromWeek = dayAPI().subtract(21, 'week').set('second', 0).unix();
    /** 使用 tradeDate 尋找前20日交易日的日期 */
    const fromDay = dayAPI(tradeDate[19]).set('second', 0).unix();
    //------------------------------------------------
    // 日層級的資料
    const dataOfDays = useGetDataInfo({ symbol, resolution: 'D', from: fromDay, refresh: 1 }).data ?? emptyData;
    // 周層級的資料
    const dataOfWeeks = useGetDataInfo({ symbol, resolution: 'SW', from: fromWeek, refresh: 5 }).data ?? emptyData;
    // 月層級的資料
    const dataOfMonths = useGetDataInfo({ symbol, resolution: 'SM', from: fromMounth, refresh: 5 }).data ?? emptyData;
    const contractState = useSnapshot(contractStore);
    useEffect(() => {
        // 儲存交易資料
        if (dataOfDays !== emptyData) {
            contractStore.dayData = dataOfDays;
        }
    }, [dataOfDays]);
    const snapshotDataOfDays = contractState.dayData;
    //------------------------------------------------
    // 讀取資料的時間
    const timeOfDay = snapshotDataOfDays.t.slice(-21);
    const timeOfWeek = dataOfWeeks.t.slice(-21);
    const timeOfMonth = dataOfMonths.t.slice(-21);
    // 讀取日資料的高低價
    const dayOfHighData = snapshotDataOfDays.h.slice(-21);
    const dayOfLowData = snapshotDataOfDays.l.slice(-21);
    // 讀取周資料的高低價
    const weekOfHighData = dataOfWeeks.h.slice(-21);
    const weekOfLowData = dataOfWeeks.l.slice(-21);
    // 讀取月資料的高低價
    const monthOfOpenData = dataOfMonths.o.slice(-21);
    const monthOfHighData = dataOfMonths.h.slice(-21);
    const monthOfLowData = dataOfMonths.l.slice(-21);
    const monthOfCloseData = dataOfMonths.c.slice(-21);
    //------------------------------------------------
    // 日期資料長度
    const dataOfDayLenght = timeOfDay.length;
    const dataOfweekLenght = timeOfWeek.length;
    const dataOfMonthLenght = timeOfMonth.length;
    //------------------------------------------------
    //月合約資訊
    const contractHigh = monthOfHighData[dataOfMonthLenght - 1];
    const contractLow = monthOfLowData[dataOfMonthLenght - 1];
    const contractOpen = monthOfOpenData[dataOfMonthLenght - 1];
    const contractClose = monthOfCloseData[dataOfMonthLenght - 1];
    // 月成本 ＝ (月最高 + 月最低) / 2
    const monthCost = (monthOfHighData[dataOfMonthLenght - 1] + monthOfLowData[dataOfMonthLenght - 1]) / 2;
    //------------------------------------------------
    //本月最低
    const monthLow = monthOfLowData[dataOfMonthLenght - 1];
    //本週最低
    const weekLow = weekOfLowData[dataOfweekLenght - 1];
    //本日最低
    const dayLow = dayOfLowData[dataOfDayLenght - 1];
    //本月最高
    const monthHigh = monthOfHighData[dataOfMonthLenght - 1];
    //本週最高
    const weekHigh = weekOfHighData[dataOfweekLenght - 1];
    //本日最高
    const dayHigh = dayOfHighData[dataOfDayLenght - 1];
    //本月振幅
    const monthAmp = monthHigh - monthLow;
    //本週振幅
    const weekAmp = weekHigh - weekLow;
    //本日振幅
    const dayAmp = high - low;
    //------------------------------------------------
    // 依據現在選擇的'日'、'週'、'月' 返回該週期的資料
    const dataOfInterval = () => {
        if (tabState === 'month') {
            return dataOfMonths;
        }
        else if (tabState === 'week') {
            return dataOfWeeks;
        }
        else {
            return snapshotDataOfDays;
        }
    };
    // 依據現在選擇'日'、'週'、'月' 返回該週期的資料長度
    const dataLength = () => {
        if (tabState === 'month') {
            return dataOfMonthLenght;
        }
        else if (tabState === 'week') {
            return dataOfweekLenght;
        }
        else {
            return dataOfDayLenght;
        }
    };
    // 計算日/周/月振幅
    const data = dailyAmplitudeStatsCalculator(dataOfInterval(), dataLength());
    //根據現在選擇'日'、'週'、'月' 返回該週期的最高或最低
    const intervalLow = () => {
        if (tabState === 'month') {
            return monthLow;
        }
        else if (tabState === 'week') {
            return weekLow;
        }
        else {
            return dayLow;
        }
    };
    const intervalHigh = () => {
        if (tabState === 'month') {
            return monthHigh;
        }
        else if (tabState === 'week') {
            return weekHigh;
        }
        else {
            return dayHigh;
        }
    };
    const maxAmp = data.max;
    const maxAvgAmp = data.aboveAverage;
    const avgAmp = data.average;
    const minAvgAmp = data.belowAverage;
    const minAmp = data.min;
    const longLevel5 = intervalLow() + maxAmp;
    const longLevel4 = intervalLow() + maxAvgAmp;
    const longLevel3 = intervalLow() + avgAmp;
    const longLevel2 = intervalLow() + minAvgAmp;
    const longLevel1 = intervalLow() + minAmp;
    const shortLevel1 = intervalHigh() - minAmp;
    const shortLevel2 = intervalHigh() - minAvgAmp;
    const shortLevel3 = intervalHigh() - avgAmp;
    const shortLevel4 = intervalHigh() - maxAvgAmp;
    const shortLevel5 = intervalHigh() - maxAmp;
    //目前對應的週期振幅
    const intervalAmp = () => {
        if (tabState === 'month') {
            return monthAmp;
        }
        else if (tabState === 'week') {
            return weekAmp;
        }
        else
            return dayAmp;
    };
    //顯示中文目前的頻率
    const intervalString = () => {
        if (tabState === 'month') {
            return '月';
        }
        else if (tabState === 'week') {
            return '週';
        }
        else
            return '日';
    };
    const longLevelData = [
        { levelString: '關卡價5', value: longLevel5, color: '#a11100' },
        { levelString: '關卡價4', value: longLevel4, color: '#911100' },
        { levelString: '關卡價3', value: longLevel3, color: '#751100' },
        { levelString: '關卡價2', value: longLevel2, color: '#651100' },
        { levelString: '關卡價1', value: longLevel1, color: '#551100' },
        { levelString: '最低價', value: intervalLow(), color: '#363839' },
    ];
    const shortLevelData = [
        { levelString: '最高價', value: intervalHigh(), color: '#363839' },
        { levelString: '關卡價1', value: shortLevel1, color: '#115500' },
        { levelString: '關卡價2', value: shortLevel2, color: '#116500' },
        { levelString: '關卡價3', value: shortLevel3, color: '#117500' },
        { levelString: '關卡價4', value: shortLevel4, color: '#119100' },
        { levelString: '關卡價5', value: shortLevel5, color: '#11a100' },
    ];
    const contractPriceData = [
        { levelString: '合約開', value: contractOpen, color: '#00000000' },
        { levelString: '合約高', value: contractHigh, color: '#00000000' },
        { levelString: '合約低', value: contractLow, color: '#00000000' },
        { levelString: '月成本', value: monthCost, color: '#00000000' },
    ];
    const ampData = [
        { levelString: '最大振幅', value: maxAmp, color: '#585e61' },
        { levelString: '大大振幅', value: maxAvgAmp, color: '#585e61' },
        { levelString: '平均振幅', value: avgAmp, color: '#585e61' },
        { levelString: '小小振幅', value: minAvgAmp, color: '#585e61' },
        { levelString: '最小振幅', value: minAmp, color: '#585e61' },
        { levelString: '本' + intervalString() + '振幅', value: intervalAmp(), color: '#c98a00' },
    ];
    // 月振幅資料
    const monthData = dailyAmplitudeStatsCalculator(dataOfMonths, dataOfMonthLenght);
    const contractKbarData = {
        open: contractOpen,
        high: contractHigh,
        low: contractLow,
        close: contractClose,
        averRange: monthData.average,
        maxRange: monthData.max,
    };
    // 計算近遠月差價
    const value1 = useSnapshot(signalrStore2.values.quote)['TX-1'];
    const value2 = useSnapshot(signalrStore2.values.quote)['TX-2'];
    // 計算期現貨差價
    const indexValue = useSnapshot(signalrStore2.values.quote)['TSEA'];
    const futuresPriceDiff = (value1?.close ?? 0) - (value2?.close ?? 0);
    const indexPriceDiff = (value?.close ?? 0) - (indexValue?.close ?? 0);
    return (<div css={classes.contractData.container}>
      <div css={css `
          ${classes.title};
          background-color: #4a4a4a;
        `}>
        台指合約相關資訊
      </div>

      <div css={classes.symbolSelecter}>
        商品:
        <div onClick={() => {
            freemanStore.tradeSymbol = 'TXAM-1';
            store.charting.changeSymbol('TXAM-1');
        }} css={state.tradeSymbol === 'TXAM-1' ? classes.switchButtomSelected : classes.switchButtom}>
          台指期(日)
        </div>
        <div css={css `
            ${fill_horizontal_all_center};
            ${state.tradeSymbol === 'TX-1' ? classes.switchButtomSelected : classes.switchButtom};
            width: 83px;
          `} onClick={() => {
            freemanStore.tradeSymbol = 'TX-1';
            store.charting.changeSymbol('TX-1');
        }}>
          台指期(全)
        </div>
      </div>

      <div css={classes.contractData.kbarInfo}>
        <div>
          <PriceLevelItem data={contractPriceData}/>
        </div>
        <MonthContractKbar contractData={contractKbarData}/>
      </div>
      <div css={classes.priceDiff.container}>
        <PriceDiff indexPriceDiff={indexPriceDiff} futuresPriceDiff={futuresPriceDiff}/>
      </div>
      {props_.children}
      <div css={css `
          ${classes.title};
          background-color: #952c2c;
        `}>
        {intervalString()}多方關卡價
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={longLevelData}/>
      </div>
      <div css={css `
          ${classes.title};
          background-color: #2c952c;
        `}>
        {intervalString()}空方關卡價
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={shortLevelData}/>
      </div>
      <div css={css `
          ${classes.title};
          background-color: #4a4a4a;
        `}>
        {intervalString()}振幅統計(近20{intervalString()})
      </div>
      <div css={css `
          ${classes.priceLevel.container};
          gap: 1px;
        `}>
        <PriceLevelItem data={ampData.sort((a, b) => b.value - a.value)}/>
      </div>
    </div>);
});
export const Freeman_SidePane1 = memo(function Freeman_SidePane1() {
    return (<div css={classes.container}>
      <ContractData>
        <IntervalSwitch />
      </ContractData>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
    switchButtom: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #232323;
    color: #aaaaaa;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    switchButtomSelected: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e48100;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    title: css `
    ${fill_vertical_all_center};
    height: 24px;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
    symbolSelecter: css `
    ${fill_horizontal_cross_center};
    height: 30px;
    background-color: #353535;
    gap: 8px;
    padding: 8px;
  `,
    priceDiff: {
        container: css `
      width: 100%;
      height: 64px;
      background-color: #1e1d1d;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #888888;
    `,
    },
    contractData: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      font-size: 14px;
    `,
        kbarInfo: css `
      width: 100%;
      display: grid;
      grid-template-columns: 68% 32%;
      background-color: #1e1d1d;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #888888;
    `,
    },
    contractInfo: {
        container: css `
      ${fill_vertical_all_center};
      background-color: #1e1d1d;
      height: 132px;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
    },
    priceLevel: {
        container: css `
      ${fill_vertical_all_center};
      height: auto;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
    },
    ampTitle: css `
    ${fill_vertical_all_center};
    height: 24px;
    background-color: #4a4a4a;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
