export const daysVolumeStatsDeviationCalculator = (data) => {
    const volumeData = data.map(item => {
        return item.volume;
    });
    const sum = volumeData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // 50%
    const average = volumeData.length > 0 ? sum / volumeData.length : 0;
    const variance = volumeData.reduce((accumulator, currentValue) => accumulator + Math.pow(currentValue - average, 2), 0) / volumeData.length;
    const standardDeviation = Math.sqrt(variance);
    // 2.5%
    const max = average + standardDeviation * 3;
    // 97.5%
    const min = average - standardDeviation * 3;
    // 84%
    const belowAverage = average - standardDeviation;
    // 16%
    const aboveAverage = average + standardDeviation;
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
