import { css } from '@emotion/react';
import React, { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import dayAPI from '~/utils/dayAPI';
import { proxy, useSnapshot } from 'valtio';
import { dailyIncreaseAmountStatsDeviationCalculator, dailyDecreaseAmountStatsDeviationCalculator, } from '~/pages/freeman/component/dailyAmplitudeStatsCalculator';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useGetDataInfo } from '~/pages/freeman/freeman_SidePane1';
import { PriceLevelAndProbabilityItem } from '~/pages/win168/_private/Component/PriceLevelAndProbabilityItem';
import { win168_store } from '~/pages/win168/_private/win168_store';
/** 各週期的空值資料typing */
const emptyData = {
    t: [0],
    o: [0],
    h: [0],
    l: [0],
    c: [0],
    v: [0],
    s: 'ok',
};
//快照 當資料從空值轉為有數值時 給與新值
const contractStore = proxy({
    dayData: emptyData,
    // weekData: emptyData as TVAPISymbol,
    // monthData: emptyData as TVAPISymbol,
});
export const supportAndResistancePriceStore = proxy({
    support: [],
    resistance: [],
    // weekData: emptyData as TVAPISymbol,
    // monthData: emptyData as TVAPISymbol,
});
const ContractData = memo(function SidePane(props_) {
    const symbol = 'TXAM-1';
    const nightMarketSymbol = 'TXPM-1';
    const state = useSnapshot(win168_store);
    // console.log('state', state)
    const bs = state.direction === 'long' ? 'b' : 's';
    const tradeDate = useSnapshot(staticStore.tradedDate.days);
    // console.log('tradeDate[0]', tradeDate[0])
    //------------------------------------------------
    const length = 1;
    /** 使用前21個月交易日的日期 */
    const fromMounth = dayAPI().subtract(length, 'month').set('second', 0).unix();
    /** 使用前21個周交易日的日期 */
    const fromWeek = dayAPI().subtract(length, 'week').set('second', 0).unix();
    /** 使用 tradeDate 尋找前20日交易日的日期 */
    const fromDay = dayAPI(tradeDate[19]).set('second', 0).unix();
    //------------------------------------------------
    // 日層級的資料
    const dataOfDays = useGetDataInfo({ symbol, resolution: 'D', from: fromDay, refresh: 1 }).data ?? emptyData;
    // 周層級的資料
    const dataOfWeeks = useGetDataInfo({ symbol, resolution: 'SW', from: fromWeek, refresh: 5 }).data ?? emptyData;
    // 月層級的資料
    const dataOfMonths = useGetDataInfo({ symbol, resolution: 'SM', from: fromMounth, refresh: 5 }).data ?? emptyData;
    // 夜盤資料
    const nightMarketData = useGetDataInfo({
        symbol: nightMarketSymbol,
        resolution: 'D',
        from: tradeDate[0].unix(),
        refresh: 1,
    }).data ?? emptyData;
    // console.log('nightMarketData', nightMarketData)
    // useEffect(() => {
    //   // 儲存交易資料
    //   if (dataOfDays !== emptyData) {
    //     contractStore.dayData = dataOfDays
    //   }
    // }, [dataOfDays])
    // const snapshotDataOfDays = contractState.dayData
    //------------------------------------------------
    // 讀取資料的時間
    const timeOfDay = dataOfDays.t.slice(-21);
    // 讀取日資料的高低價
    const dayOfHighData = dataOfDays.h.slice(-21);
    const dayOfLowData = dataOfDays.l.slice(-21);
    const dayOfOpenData = dataOfDays.o.slice(-21);
    // 讀取周資料的高低價
    const weekOfHighData = dataOfWeeks.h.slice(-length);
    const weekOfLowData = dataOfWeeks.l.slice(-length);
    // 讀取月資料的高低價
    const monthOfOpenData = dataOfMonths.o.slice(-length);
    const monthOfHighData = dataOfMonths.h.slice(-length);
    const monthOfLowData = dataOfMonths.l.slice(-length);
    const monthOfCloseData = dataOfMonths.c.slice(-length);
    //------------------------------------------------
    // 日期資料長度
    const dataOfDayLenght = timeOfDay.length;
    //------------------------------------------------
    //月合約資訊
    const contractHigh = monthOfHighData[length - 1];
    const contractLow = monthOfLowData[length - 1];
    const contractOpen = monthOfOpenData[length - 1];
    const contractClose = monthOfCloseData[length - 1];
    //------------------------------------------------
    //本月最低
    const monthLow = contractLow;
    //本週最低
    const weekLow = weekOfLowData[length - 1];
    //本日最低
    const dayLow = dayOfLowData[dataOfDayLenght - 1];
    //昨日最低
    const yesterdayLow = dayOfLowData[dataOfDayLenght - 2];
    // 夜盤最低
    const nightMarketLow = nightMarketData.l[nightMarketData.l.length - 1];
    //本月最高
    const monthHigh = contractHigh;
    //本週最高
    const weekHigh = weekOfHighData[length - 1];
    //本日最高
    const dayHigh = dayOfHighData[dataOfDayLenght - 1];
    //本日開盤
    const dayOfOpen = dayOfOpenData[dataOfDayLenght - 1];
    //昨日最高
    const yesterdayHigh = dayOfHighData[dataOfDayLenght - 2];
    // 夜盤最高
    const nightMarketHigh = nightMarketData.h[nightMarketData.h.length - 1];
    // 計算平均日振幅
    const increaseAmountDeviationData = dailyIncreaseAmountStatsDeviationCalculator(dataOfDays, dataOfDayLenght);
    const decreaseAmountDeviationData = dailyDecreaseAmountStatsDeviationCalculator(dataOfDays, dataOfDayLenght);
    const maxIncreaseAmount = increaseAmountDeviationData.max;
    const maxAvgIncreaseAmount = increaseAmountDeviationData.aboveAverage;
    const avgIncreaseAmount = increaseAmountDeviationData.average;
    const minAvgIncreaseAmount = increaseAmountDeviationData.belowAverage;
    const minIncreaseAmount = increaseAmountDeviationData.min;
    const maxDecreaseAmount = decreaseAmountDeviationData.max;
    const maxAvgDecreaseAmount = decreaseAmountDeviationData.aboveAverage;
    const avgDecreaseAmount = decreaseAmountDeviationData.average;
    const minAvgDecreaseAmount = decreaseAmountDeviationData.belowAverage;
    const minDecreaseAmount = decreaseAmountDeviationData.min;
    const longLevel5 = dayOfOpen + maxIncreaseAmount;
    const longLevel4 = dayOfOpen + maxAvgIncreaseAmount;
    const longLevel3 = dayOfOpen + avgIncreaseAmount;
    const longLevel2 = dayOfOpen + minAvgIncreaseAmount;
    const longLevel1 = dayOfOpen + minIncreaseAmount;
    const shortLevel1 = dayOfOpen - minDecreaseAmount;
    const shortLevel2 = dayOfOpen - minAvgDecreaseAmount;
    const shortLevel3 = dayOfOpen - avgDecreaseAmount;
    const shortLevel4 = dayOfOpen - maxAvgDecreaseAmount;
    const shortLevel5 = dayOfOpen - maxDecreaseAmount;
    // 產生longInsertedData，這個array有階級之分，如果順位低的數據等於數據高的數據則不顯示，例如：週最高價等於月最高價，則只顯示月最高價
    const longInsertedData = [
        { levelString: '當月最高價', value: monthHigh },
        ...(weekHigh !== monthHigh ? [{ levelString: '當週最高價', value: weekHigh }] : []),
        ...(contractOpen !== monthHigh && contractOpen !== weekHigh
            ? [{ levelString: '當月開盤價', value: contractOpen }]
            : []),
        ...(yesterdayHigh !== contractOpen && yesterdayHigh !== weekHigh && yesterdayHigh !== monthHigh
            ? [{ levelString: '昨日最高價', value: yesterdayHigh }]
            : []),
        ...(nightMarketHigh !== yesterdayHigh &&
            nightMarketHigh !== contractOpen &&
            nightMarketHigh !== weekHigh &&
            nightMarketHigh !== monthHigh
            ? [{ levelString: '夜盤最高價', value: nightMarketHigh }]
            : []),
        ...(nightMarketLow !== yesterdayHigh &&
            nightMarketLow !== contractOpen &&
            nightMarketLow !== weekHigh &&
            nightMarketLow !== monthHigh &&
            nightMarketLow !== nightMarketHigh
            ? [{ levelString: '夜盤最低價', value: nightMarketLow }]
            : []),
    ];
    const basicLongLevelData = [
        { levelString: '目標價5', value: longLevel5, probability: '3%', color: '#a11100' },
        { levelString: '目標價4', value: longLevel4, probability: '16%', color: '#911100' },
        { levelString: '目標價3', value: longLevel3, probability: '50%', color: '#751100' },
        { levelString: '目標價2', value: longLevel2, probability: '84%', color: '#651100' },
        { levelString: '目標價1', value: longLevel1, probability: '98%', color: '#551100' },
        { levelString: '當日開盤價', value: dayOfOpen, probability: '100%', color: '#363839' },
    ];
    const mergedLongLevelData = [...basicLongLevelData];
    longInsertedData.forEach(insertedItem => {
        for (let i = 0; i < mergedLongLevelData.length - 1; i++) {
            if (insertedItem.value > mergedLongLevelData[i].value) {
                if (i === 0)
                    break;
                // 取得前後概率和顏色
                const prevProbability = i === 0 ? 100 : parseFloat(mergedLongLevelData[i - 1].probability);
                const nextProbability = parseFloat(mergedLongLevelData[i].probability);
                const newProbability = ((prevProbability + nextProbability) / 2).toFixed(0) + '%';
                const newColor = i === 0 ? '#363839' : mergedLongLevelData[i - 1].color;
                // 插入新項目並更新插入狀態
                // 在區間之外，則不添加
                mergedLongLevelData.splice(i, 0, {
                    ...insertedItem,
                    probability: newProbability,
                    color: newColor,
                });
                break;
            }
        }
    });
    // 將 mergedLongLevelData 賦值回 longLevelData
    const longLevelData = mergedLongLevelData;
    supportAndResistancePriceStore.resistance = longLevelData;
    const shortInsertedData = [
        { levelString: '當月最低價', value: monthLow },
        ...(weekLow !== monthLow ? [{ levelString: '當週最低價', value: weekLow }] : []),
        ...(contractOpen !== monthLow && contractOpen !== weekLow
            ? [{ levelString: '當月開盤價', value: contractOpen }]
            : []),
        ...(yesterdayLow !== contractOpen && yesterdayLow !== weekLow && yesterdayLow !== monthLow
            ? [{ levelString: '昨日最低價', value: yesterdayLow }]
            : []),
        ...(nightMarketLow !== yesterdayLow &&
            nightMarketLow !== contractOpen &&
            nightMarketLow !== weekLow &&
            nightMarketLow !== monthLow
            ? [{ levelString: '夜盤最低價', value: nightMarketLow }]
            : []),
        ...(nightMarketHigh !== yesterdayHigh &&
            nightMarketHigh !== contractOpen &&
            nightMarketHigh !== weekHigh &&
            nightMarketHigh !== monthHigh &&
            nightMarketHigh !== nightMarketLow
            ? [{ levelString: '夜盤最高價', value: nightMarketHigh }]
            : []),
    ];
    // console.log('shortInsertedData', shortInsertedData)
    const basicShortLevelData = [
        { levelString: '當日開盤價', value: dayOfOpen, probability: '100%', color: '#363839' },
        { levelString: '目標價1', value: shortLevel1, probability: '98%', color: '#115500' },
        { levelString: '目標價2', value: shortLevel2, probability: '84%', color: '#116500' },
        { levelString: '目標價3', value: shortLevel3, probability: '50%', color: '#117500' },
        { levelString: '目標價4', value: shortLevel4, probability: '16%', color: '#119100' },
        { levelString: '目標價5', value: shortLevel5, probability: '3%', color: '#11a100' },
    ];
    // 合併 shortInsertedData 和 basicShortLevelData
    const mergedShortLevelData = [...basicShortLevelData];
    shortInsertedData.forEach(insertedItem => {
        for (let i = 0; i < mergedShortLevelData.length; i++) {
            if (insertedItem.value > mergedShortLevelData[i].value) {
                if (i === 0)
                    break;
                // 按照從高到低排序
                // 計算插入位置的前後概率和顏色
                const prevProbability = i === 0 ? 100 : parseFloat(mergedShortLevelData[i - 1].probability);
                const nextProbability = parseFloat(mergedShortLevelData[i].probability);
                const newProbability = ((prevProbability + nextProbability) / 2).toFixed(0) + '%';
                const newColor = i === 0 ? '#363839' : mergedShortLevelData[i - 1].color;
                // 插入新項目
                // 在區間之外，則不添加
                mergedShortLevelData.splice(i, 0, {
                    ...insertedItem,
                    probability: newProbability,
                    color: newColor,
                });
                break;
            }
        }
    });
    // 最後的結果賦值到 shortLevelData
    const shortLevelData = mergedShortLevelData;
    supportAndResistancePriceStore.support = shortLevelData;
    return (<div css={classes.contractData.container}>
      {bs === 'b' ? (<>
          <div css={css `
              ${classes.title};
              background-color: #952c2c;
            `}>
            日多方目標價
          </div>

          <div css={css `
              width: 100%;
            `}>
            <div css={css `
                ${classes.priceLevel.container};
                gap: 1px;
              `}>
              <div css={css `
                  ${classes.priceLevel.title.container};
                `}>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  名稱
                </span>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  點數
                </span>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  機率
                </span>
              </div>
              <PriceLevelAndProbabilityItem data={longLevelData}/>
            </div>
          </div>
        </>) : (<>
          <div css={css `
              ${classes.title};
              background-color: #2c952c;
            `}>
            日空方目標價
          </div>
          <div css={css `
              width: 100%;
            `}>
            <div css={css `
                ${classes.priceLevel.container};
                gap: 1px;
              `}>
              <div css={css `
                  ${classes.priceLevel.title.container};
                `}>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  名稱
                </span>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  點數
                </span>
                <span css={css `
                    ${classes.priceLevel.title.content};
                  `}>
                  機率
                </span>
              </div>
              <PriceLevelAndProbabilityItem data={shortLevelData}/>
            </div>
          </div>
        </>)}
    </div>);
});
export const DayLevelPrice = memo(function DayLevelPrice() {
    return (<div css={classes.container}>
      <ContractData />
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
    switchButtom: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #232323;
    color: #aaaaaa;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    switchButtomSelected: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e48100;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    title: css `
    ${fill_vertical_all_center};
    height: 24px;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
    symbolSelecter: css `
    ${fill_horizontal_cross_center};
    height: 30px;
    background-color: #353535;
    gap: 8px;
    padding: 8px;
  `,
    priceDiff: {
        container: css `
      width: 100%;
      height: 64px;
      background-color: #1e1d1d;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #888888;
    `,
    },
    contractData: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      font-size: 14px;
    `,
        kbarInfo: css `
      width: 100%;
      display: grid;
      grid-template-columns: 68% 32%;
      background-color: #1e1d1d;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #888888;
    `,
    },
    contractInfo: {
        container: css `
      ${fill_vertical_all_center};
      background-color: #1e1d1d;
      height: 132px;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
    },
    priceLevel: {
        container: css `
      ${fill_vertical_all_center};
      height: auto;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #888888;
    `,
        title: {
            container: css `
        ${fill_horizontal_all_center};
        ${jc.spaceBetween};
        height: 28px;
        background-color: #4a4a4a;
        border-radius: 4px;
        padding: 0 4px;
        font-size: 14px;
        font-weight: bold;
      `,
            content: css `
        ${fill_horizontal_all_center};
        padding: 0 4px;
        border-radius: 4px;
        font-weight: 500;
        /* background-color: #5959596b; */
        padding: 0 4px;
        width: 48%;
        height: 22px;
      `,
        },
    },
    ampTitle: css `
    ${fill_vertical_all_center};
    height: 24px;
    background-color: #4a4a4a;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
    font-weight: bold;
  `,
};
