import { css } from '@emotion/react';
import React, { memo } from 'react';
import { BiUser } from 'react-icons/bi';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { store } from '~/pages/heineken_template/_private/store';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import Styled from '~/pages/win168/_private/styleds';
import { VolumeRelativeStrengthThermometer } from '~/pages/win168/_private/Component/VolumeRelativeStrengthThermometer';
import { DayLevelPrice } from '~/pages/win168/_private/Component/DayLevelPrice';
import { useSnapshot } from 'valtio';
import { win168_store } from '~/pages/win168/_private/win168_store';
import { daytradeStrategies } from '~/pages/win168/_private/win168_initStrategies';
import { useChartingSelectStrategyFn } from '~/modules/SDK/chart4/hooks/useChartingSelectStrategyFn';
export const Win168_SidePane2 = memo(function SidePane2(props) {
    return (<Styled.Sidebar>
      <div css={css `
          ${flex.h.allCenter}
          width:100%;
          background-color: #333333;
        `}>
        <ChartingServerSwitch charting={store.charting}/>
        <BiUser size={26}/>
        <UserAvatarAsDialogButton />
      </div>

      <div css={css `
          ${flex.v.allCenter}
          width: 100%;
          height: 300px;
        `}>
        <img src='/win168/logo1.png' css={css `
            width: 100%;
            height: 250px;
          `}/>
      </div>

      <Styled.Title>盤勢重點提醒＆壓力支撐關鍵價</Styled.Title>
      <div css={css `
          width: 100%;
          height: calc(100% - 350px);
        `}>
        <Styled.StrategyToggleButton.container>
          <TypeBotton type={'long'}>多方</TypeBotton>
          <TypeBotton type={'short'}>空方</TypeBotton>
        </Styled.StrategyToggleButton.container>
        {/* <ManualSignal
          agent={'win168'}
          limit={10}
        /> */}
        <DayLevelPrice />
      </div>
      <>
        <VolumeRelativeStrengthThermometer />
      </>
    </Styled.Sidebar>);
});
const TypeBotton = memo(function TypeBotton(props) {
    const state = useSnapshot(win168_store);
    const displayType = props.type === 'long' ? '多方' : '空方';
    const selected = props.type === state.direction;
    const changeIndicators = useChartingSelectStrategyFn(store.charting);
    return (<Styled.StrategyToggleButton.button selected={selected} className={props.type} onClick={() => {
            //只有豐收1號(順勢當沖-穩健)、豐收2號(順勢當沖-積極)等當沖策略才會有多空方切換
            if (store.charting.strategySelected.displayName === '豐收1號(順勢當沖-穩健)' ||
                store.charting.strategySelected.displayName === '豐收2號(順勢當沖-積極)') {
                if (daytradeStrategies[props.type]) {
                    store.charting.replaceIndicators(daytradeStrategies[props.type]);
                }
            }
            win168_store.direction = props.type;
        }}>
      {displayType}
    </Styled.StrategyToggleButton.button>);
});
