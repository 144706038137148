import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { fill_vertical_cross_center, fill_horizontal_all_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
const Container = styled.div `
  ${fill_vertical_cross_center}
  background: rgba(0, 0, 0, 0.6);
  padding: 6px;
  /* padding-top: 16px; */
  position: relative;
`;
const Base = styled.div `
  width: 30px;
  height: 30px;
  background-color: ${props => props.backgroundColor};
  border-radius: 50px;
`;
const Line = styled.div `
  height: 12px;
  min-width: 240px;
  /* background-image: linear-gradient(
    to right,
    #0fcd11 0%,
    #35764e 16.6%,
    #01c8a1 66.6%,
    #bf4045 83.3%,
    #f63538 100%
  ); */
  background-color: ${props => props.backgroundColor};
  border-radius: 50px;
  margin-left: -10px;
  position: relative;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.25) 90%,
    black 90%,
    black
  );
`;
const Value = styled.div `
  position: absolute;
  /* top: 120px;
  right: -92px;
  transform: rotate(-90deg); */
  font-size: 11px;
  color: #fff;
  display: flex;
  height: 12px;
  width: 200px;
  text-align: right;
  justify-content: flex-end;
  gap: 2px;
`;
const Ticks = styled.div `
  position: absolute;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 10%,
    transparent 10%
  );

  /* background-size: 20px 5px; */
  background-size: calc(100% / 6) 5px;
  background-repeat: repeat-X;
  width: 100%;
  height: 100%;
  z-index: 999;
`;
export function Thermometer({ value, maxValue }) {
    const [currentValue, setCurrentValue] = useState(value);
    if (value > maxValue) {
        value = maxValue;
    }
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    const percentage = (1 - currentValue / maxValue) * 100;
    const color = getColor(currentValue / maxValue);
    return (<Container>
      <div css={css `
          /* width: 100%; */
          ${fill_horizontal_all_center}
        `}>
        <div css={css `
            color: ${color};
            font-size: 25px;
            letter-spacing: 15px;
            text-indent: 15px;
            margin-top: 4px;
          `}>
          {getName(currentValue / maxValue)}
        </div>
      </div>
      <div css={css `
          display: grid;
          grid-template-columns: 36px calc(100% - 36px);
          grid-template-rows: 48px;
          gap: 6px;
          padding: 6px;
        `}>
        <div css={css `
            ${flex.h.allCenter}
          `}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' strokeWidth='2' stroke={color} width='100%' height='100%'>
            <path strokeLinecap='round' strokeLinejoin='round' d='M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z'/>

            <path strokeLinecap='round' strokeLinejoin='round' d='M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z'/>
          </svg>
        </div>

        <div css={css `
            ${flex.h.allCenter}
          `}>
          <Base backgroundColor={color}/>
          <Line backgroundColor={color} style={{
            WebkitMaskImage: `linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ${percentage}%, black ${percentage}%, black)`,
        }}>
            <Ticks />
          </Line>
        </div>
      </div>
    </Container>);
}
function getColor(percentage) {
    const range7 = [1, 0.85, 0.67, 0.5, 0.34, 0.17, 0];
    // [0,1]
    if (percentage > range7[1])
        return '#f63538';
    // [1,2]
    if (percentage > range7[2])
        return '#bf4045';
    // [2,3]
    if (percentage > range7[3])
        return '#01c8a1';
    // [5,6]
    if (percentage < range7[5])
        return '#2f9e4f';
    // [4,5]
    if (percentage < range7[4])
        return '#35764e';
    // [3,4]
    if (percentage < range7[3])
        return '#01c8a1';
    return '#01c8a1';
}
function getName(percentage) {
    const range7 = [1, 0.85, 0.67, 0.5, 0.34, 0.17, 0];
    // [0,1]
    if (percentage > range7[1])
        return '火熱盤';
    // [1,2]
    if (percentage > range7[2])
        return '溫暖盤';
    // [2,3]
    if (percentage > range7[3])
        return '恆溫盤';
    // [5,6]
    if (percentage < range7[5])
        return '嚴寒盤';
    // [4,5]
    if (percentage < range7[4])
        return '寒冷盤';
    // [3,4]
    if (percentage < range7[3])
        return '恆溫盤';
    return '恆溫盤';
}
