export const dailyAmplitudeStatsCalculator = (data, numDays) => {
    const dailyAmplitudes = [];
    const dataHigh = data.h.slice(0, -1);
    const dataLow = data.l.slice(0, -1);
    const dataData = data.t.slice(0, -1);
    for (let i = dataData.length; i >= Math.max(dataData.length - numDays, 0); i--) {
        const amplitude = dataHigh[i] - dataLow[i];
        dailyAmplitudes.unshift(amplitude);
    }
    dailyAmplitudes.pop();
    const sum = dailyAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = dailyAmplitudes.length > 0 ? sum / dailyAmplitudes.length : 0;
    const max = dailyAmplitudes.length > 0 ? Math.max(...dailyAmplitudes) : 0;
    const min = dailyAmplitudes.length > 0 ? Math.min(...dailyAmplitudes) : 0;
    //console.log('max:', max, 'min:', min, 'all:', dailyAmplitudes)
    const belowAverageAmplitudes = dailyAmplitudes.filter(amplitude => amplitude < average);
    const aboveAverageAmplitudes = dailyAmplitudes.filter(amplitude => amplitude > average);
    const belowAverage = belowAverageAmplitudes.length > 0
        ? belowAverageAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / belowAverageAmplitudes.length
        : 0;
    const aboveAverage = aboveAverageAmplitudes.length > 0
        ? aboveAverageAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / aboveAverageAmplitudes.length
        : 0;
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
export const dailyAmplitudeStatsDeviationCalculator = (data, numDays) => {
    const dailyAmplitudes = [];
    const dataHigh = data.h.slice(0, -1);
    const dataLow = data.l.slice(0, -1);
    const dataData = data.t.slice(0, -1);
    for (let i = dataData.length; i >= Math.max(dataData.length - numDays, 0); i--) {
        const amplitude = dataHigh[i] - dataLow[i];
        dailyAmplitudes.unshift(amplitude);
    }
    dailyAmplitudes.pop();
    const sum = dailyAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // 50%
    const average = dailyAmplitudes.length > 0 ? sum / dailyAmplitudes.length : 0;
    const variance = dailyAmplitudes.reduce((accumulator, currentValue) => accumulator + Math.pow(currentValue - average, 2), 0) / dailyAmplitudes.length;
    const standardDeviation = Math.sqrt(variance);
    // 2.5%
    const max = average + standardDeviation * 2;
    // 97.5%
    const min = average - standardDeviation * 2;
    // 84%
    const belowAverage = average - standardDeviation;
    // 16%
    const aboveAverage = average + standardDeviation;
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
export const dailyDecreaseAmountStatsDeviationCalculator = (data, numDays) => {
    const dailyDecreaseAmount = [];
    const dataLow = data.l.slice(0, -1);
    const dataOpen = data.o.slice(0, -1);
    const dataData = data.t.slice(0, -1);
    for (let i = dataData.length; i >= Math.max(dataData.length - numDays, 0); i--) {
        const increaseAmount = dataOpen[i] - dataLow[i];
        dailyDecreaseAmount.unshift(increaseAmount);
    }
    dailyDecreaseAmount.pop();
    const sum = dailyDecreaseAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // 50%
    const average = dailyDecreaseAmount.length > 0 ? sum / dailyDecreaseAmount.length : 0;
    const variance = dailyDecreaseAmount.reduce((accumulator, currentValue) => accumulator + Math.pow(currentValue - average, 2), 0) / dailyDecreaseAmount.length;
    const standardDeviation = Math.sqrt(variance);
    // 2.5%
    const max = average + standardDeviation * 2;
    // 97.5%
    // 防止有負數
    const min = average - standardDeviation * 2 < 0
        ? Math.min(...dailyDecreaseAmount)
        : average - standardDeviation * 2;
    // 84%
    const belowAverage = average - standardDeviation;
    // 16%
    const aboveAverage = average + standardDeviation;
    // console.log('max:', max, 'min:', min, 'all:', dailyDecreaseAmount)
    // console.log('average:', average, 'standardDeviation:', standardDeviation)
    // console.log('belowAverage:', belowAverage, 'aboveAverage:', aboveAverage)
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
export const dailyIncreaseAmountStatsDeviationCalculator = (data, numDays) => {
    const dailyIncreaseAmount = [];
    const dataHigh = data.h.slice(0, -1);
    const dataOpen = data.o.slice(0, -1);
    const dataData = data.t.slice(0, -1);
    for (let i = dataData.length; i >= Math.max(dataData.length - numDays, 0); i--) {
        const increaseAmount = dataHigh[i] - dataOpen[i];
        dailyIncreaseAmount.unshift(increaseAmount);
    }
    dailyIncreaseAmount.pop();
    const sum = dailyIncreaseAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // 50%
    const average = dailyIncreaseAmount.length > 0 ? sum / dailyIncreaseAmount.length : 0;
    const variance = dailyIncreaseAmount.reduce((accumulator, currentValue) => accumulator + Math.pow(currentValue - average, 2), 0) / dailyIncreaseAmount.length;
    const standardDeviation = Math.sqrt(variance);
    // 2.5%
    const max = average + standardDeviation * 2;
    // 97.5%
    // 防止有負數
    const min = average - standardDeviation * 2 < 0
        ? Math.min(...dailyIncreaseAmount)
        : average - standardDeviation * 2;
    // 84%
    const belowAverage = average - standardDeviation;
    // 16%
    const aboveAverage = average + standardDeviation;
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
