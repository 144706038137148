import useSWR from 'swr-0-5-6';
/** 測試中的預估量 tvapi */
const baseurl = 'https://tvapi.futures-ai.com/history';
export const useEstimatedVolume = (options) => {
    let symbol = options?.symbol || 'TSEA';
    const resolution = options?.resoultion || 1;
    const days = options?.days || 1;
    symbol = symbol + '%23estimated_volume';
    const url = `${baseurl}?symbol=${symbol}&resolution=${resolution}&days=${days}`;
    const res = useSWR(url, {
        revalidateOnFocus: false,
        refreshInterval: options?.refreshInterval ?? 10000,
    });
    return res;
};
