import range from 'lodash/range';
import { daysVolumeStatsDeviationCalculator } from '~/pages/win168/_private/function/volumeStatsCalculator';
export const getVolumeRelativeStrongorWeak = (latestVolume, maxDaysVolume, minDaysVolume) => {
    // 確保不會有步長為零的情況
    const step = maxDaysVolume !== minDaysVolume ? -(maxDaysVolume - minDaysVolume) / 6 : -1; // 避免 maxDaysVolume 等於 minDaysVolume 時步長為 0
    // 確認 range 函數可以處理負步長
    const range7 = range(maxDaysVolume, minDaysVolume, step).concat([minDaysVolume]);
    // [0,1]
    if (latestVolume > range7[1])
        return 6;
    // [1,2]
    if (latestVolume > range7[2])
        return 5;
    // [2,3]
    if (latestVolume > range7[3])
        return 3;
    // [5,6]
    if (latestVolume < range7[5])
        return 1;
    // [4,5]
    if (latestVolume < range7[4])
        return 2;
    return 3;
};
export const getVolumeStatsDeviationRelativeStrongorWeak = (latestVolume, data) => {
    const { average, max, min, belowAverage, aboveAverage } = daysVolumeStatsDeviationCalculator(data);
    // [0,1]
    if (latestVolume > max)
        return 6;
    // [1,2]
    if (latestVolume > aboveAverage)
        return 5;
    // [2,3]
    if (latestVolume > average)
        return 3;
    // [5,6]
    if (latestVolume < min)
        return 1;
    // [4,5]
    if (latestVolume < belowAverage)
        return 2;
    return 3;
};
