import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { supportAndResistancePriceStore } from '~/pages/win168/_private/Component/DayLevelPrice';
/** 多空分界 */
export const supportPrice = createIndicator({
    displayName: '空方支撐線',
    id: 'supportPrice',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const supportAndResistancePrice = supportAndResistancePriceStore.support.map((item, index) => {
                if (index === 0)
                    return;
                return item.value;
            });
            // 檢查數組長度並補齊 "NA"
            while (supportAndResistancePrice.length < 13) {
                supportAndResistancePrice.push(NaN);
            }
            return supportAndResistancePrice;
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
            {
                id: 'plot_6',
                type: 'line',
            },
            {
                id: 'plot_7',
                type: 'line',
            },
            {
                id: 'plot_8',
                type: 'line',
            },
            {
                id: 'plot_9',
                type: 'line',
            },
            {
                id: 'plot_10',
                type: 'line',
            },
            {
                id: 'plot_11',
                type: 'line',
            },
            {
                id: 'plot_12',
                type: 'line',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_1: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_2: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_3: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_4: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_5: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_6: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_7: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_8: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_9: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_10: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_11: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
                plot_12: {
                    color: '#ffff00',
                    transparency: 10,
                    linewidth: 1,
                    plottype: 7,
                    visible: true,
                },
            },
        },
        // palettes: {
        //   palette_0: {
        //     colors: {
        //       0: {
        //         color: '#ff0000',
        //         width: 1,
        //         style: 0,
        //       },
        //       1: {
        //         color: '#bbbb00',
        //         width: 1,
        //         style: 0,
        //       },
        //       2: {
        //         color: '#00ee00',
        //         width: 1,
        //         style: 0,
        //       },
        //     },
        //   },
        // },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
